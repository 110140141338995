import { RouterScrollProvider } from '@moxy/next-router-scroll';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';

import { useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import gsap from 'gsap/dist/gsap';

import { AppInner } from 'components/appInner/AppInner';
import { AppContext, AppContextType } from 'context/AppContext';
import {
  GlobalContentContext,
  GlobalContentContextStateType,
  GlobalContentContextType,
} from 'context/GlobalContentContext';

import { PreviewBanner } from 'components/previewBanner/PreviewBanner';
import { setCSSVar } from 'utils/helpers';
import { useWindowSize } from 'hooks/useWindowSize';
import { MainHeader } from 'components/mainHeader/MainHeader';

import style from './app.module.scss';
import 'scss/global.scss';

function MyApp(props: AppProps) {
  const router = useRouter();
  const size = useWindowSize();
  const [mainScrollLock, setMainScrollLock] = useState<number | undefined>(undefined);
  const [showMenu, setShowMenu] = useState<number | undefined>(undefined);
  const [showContact, setShowContact] = useState<number | undefined>(undefined);
  const [requestingNextProject, setRequestingNextProject] = useState<boolean | string>(false);
  const [requestingFilteredCategory, setRequestingFilteredCategory] = useState<boolean | string>(
    false,
  );
  const [showCategoryFilters, setShowCategoryFilters] = useState<boolean>(false);
  const mainRef = useRef<HTMLElement>(null);
  const mainHeaderRef = useRef<HTMLElement>(null);
  const observedStylesheets = useState<HTMLElement[]>([]);

  const [globalContentContextData, setGlobalContentContextData] = useState<
    GlobalContentContextType
  >({
    contact: {
      image_height: 0,
      image_width: 0,
      image: '',
      contact_physical: '',
      contact_digital: '',
      aspect_ratio: {
        image: 'landscape',
      },
      alt_text: '',
    },
    social_media: {},
    analytics: [],
    site_meta_data: {},
    projects: [],
    categories: [],
  });

  const globalContentContext: GlobalContentContextStateType = {
    data: globalContentContextData,
    setData: setGlobalContentContextData,
  };

  const appContext: AppContextType = {
    setMainScrollLock: setMainScrollLock,
    mainScrollLock: mainScrollLock,
    setShowMenu: setShowMenu,
    showMenu: showMenu,
    setShowContact: setShowContact,
    showContact: showContact,
    requestingNextProject,
    setRequestingNextProject,
    requestingFilteredCategory,
    setRequestingFilteredCategory,
    showCategoryFilters,
    setShowCategoryFilters,
    mainHeaderRef,
    mainRef,
  };

  useEffect(() => {
    () => {
      setCSSVar('--vh', `${size.width}px`);
      setCSSVar('--vw', `${size.height}px`);
    };
  }, [size]);

  useEffect(() => {
    document.fonts.ready.then(() => {
      document.body.classList.add('is-fonts-loaded');
    });

    // https://github.com/vercel/next.js/issues/17464#issuecomment-914561683
    if (router.pathname === '/404') {
      router.push(router.pathname);
    } else {
      router.push(router.asPath);
    }
  }, []);

  const routeChange = () => {
    // Temporary fix to avoid flash of unstyled content
    // during route transitions. Keep an eye on this
    // issue and remove this code when resolved:
    // https://github.com/vercel/next.js/issues/17464
    const tempFix = () => {
      const config = { attributes: true };
      const callback = (mutationList: any, observer: any) => {
        mutationList.forEach((mutation: any) => {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName == 'media' &&
            mutation.target.getAttribute(mutation.attributeName) === 'x'
          ) {
            mutation.target.removeAttribute('media');
          }
        });
      };
      document.querySelectorAll('style[data-n-href]').forEach((styleNode: any) => {
        if (observedStylesheets.indexOf(styleNode) === -1) {
          const observer = new MutationObserver(callback);
          observer.observe(styleNode, config);
          observedStylesheets.push(styleNode);
        }
      });
    };
    tempFix();
  };

  Router.events.on('routeChangeComplete', routeChange);
  Router.events.on('routeChangeStart', routeChange);

  return (
    <div className={style.siteContainer}>
      <Head>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={'https://mollerarchitects.com'} key="url" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <GlobalContentContext.Provider value={globalContentContext}>
        {props.router.isPreview && <PreviewBanner />}
        <RouterScrollProvider>
          <AppContext.Provider value={appContext}>
            <>
              <MainHeader />
              <main className={style.main} ref={mainRef}>
                <AppInner {...props} />
              </main>
            </>
          </AppContext.Provider>
        </RouterScrollProvider>
      </GlobalContentContext.Provider>
    </div>
  );
}

export default MyApp;
