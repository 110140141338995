import React, { useEffect, useState } from 'react';
import { Router, useRouter } from 'next/router';

import Link from 'next/link';
import gsap from 'gsap/dist/gsap';

// import { isBrowser } from 'utils/helpers';
import { useWindowSize } from 'hooks/useWindowSize';

import { useAppContext } from 'context/AppContext';
import { Contact } from 'components/contact/Contact';
import { Grid } from 'components/grid/Grid';

import { MENU_ITEMS } from 'siteConfig';
import { isDesktopWidth } from 'utils/helpers';

import style from './MainHeader.module.scss';

// if (isBrowser()) {
// Due to SSR we can only register when we are in the browser
// gsap.registerPlugin(TextPlugin);
// }

type ScrollType = {
  direction: 'up' | 'down' | undefined;
  distance: number;
  y: number;
};

export type MainHeaderType = {};
export type MainHeaderPlaceholderType = {
  blank?: boolean;
  excludeGrid?: boolean;
};

export const MainHeader = ({}: MainHeaderType): JSX.Element => {
  const {
    mainScrollLock,
    setMainScrollLock,
    showMenu,
    setShowMenu,
    showContact,
    setShowContact,
    mainHeaderRef,
    mainRef,
  } = useAppContext();
  const { asPath } = useRouter();
  const windowSize = useWindowSize();
  const slowLoad = asPath === '/' || asPath === '/home'
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(!slowLoad);
  // const [navScrollActivated, setNavScrollActivated] = useState<boolean>(false);
  // let scroll: ScrollType = {direction: 'down', distance: 0, y: 0}

  useEffect(() => {
    const desktop = isDesktopWidth(windowSize.width);
    setIsDesktop(desktop);
    window.setTimeout(() => {
      setIsLoaded(true)
    }, 2700)
  }, [windowSize.width]);

  // useEffect(() => {
  //   const onScroll = () => {
  //     const previousScroll = scroll;
  //     const y = window.scrollY;
  //     const direction = window.scrollY > previousScroll.y ? 'down' : 'up';
  //     scroll = {
  //       direction: direction,
  //       distance: direction !== previousScroll.direction ? 0 : Math.abs(y - previousScroll.y) + previousScroll.distance,
  //       y: y,
  //      }
  //     console.log(`${scroll.direction} ${previousScroll.direction}`)
  //     console.log(scroll)
  //     console.log(previousScroll)

  //     setNavScrollActivated(scroll.direction === 'up' && scroll.distance > 10 ||  scroll.y < 100)
  //   }

  //   window.addEventListener('scroll', onScroll);
  //   onScroll();

  //   return () => window.removeEventListener('scroll', onScroll);
  // }, []);

  useEffect(() => {
    // TODO potential fix for sticky items
    // https://github.com/sonsandco/refractory/blob/245f886d0c39fee3de5e04ae2f5aca894e5694bf/assets/j/main/lib/utilities/scroll_preventer.js#L243
    if (isDesktop || !mainRef.current || !mainHeaderRef.current) return;
    const mainElement = mainRef.current;
    const mainHeaderElement = mainHeaderRef.current;
    const mainRect = mainElement.getBoundingClientRect();
    // const containerRect = containerElement.getBoundingClientRect();
    if (showMenu !== undefined) {
      // show
      gsap.set(mainElement, {
        position: 'fixed',
        left: mainRect.left,
        top:  -window.scrollY,
        width: mainRect.width,
        pointerEvents: 'none',
        zIndex: -1
      });
      window.scrollTo(0, 0);
      gsap.to(mainHeaderElement, {
        autoAlpha: 1,
        duration: 0.1,
        // ease: 'sine.inOut',
        onComplete: () => {
          gsap.set(mainHeaderElement, {
            position: 'relative',
            pointerEvents: 'all',
          })
        }
      });

      setMainScrollLock(showMenu);
    } else {
      // hide
      if (!mainScrollLock) {
        gsap.set(mainElement, {
          top: 0
        })
      }
      gsap.to(mainHeaderElement, {
        autoAlpha: 0,
        duration: 0.1,
        delay: 0.3,
        // ease: 'sine.inOut',
        onComplete: () => {
          gsap.set(mainElement, { clearProps: 'all' });
          gsap.set(mainHeaderElement, { clearProps: 'all' });
          if (mainScrollLock) {
            window.scrollTo(0, mainScrollLock || 0);
            setMainScrollLock(undefined);
          }
        },
      });
    }
  }, [isDesktop, showMenu, mainRef.current, mainHeaderRef.current]);

  const onLinkClick = (e: any) => {
    const href = e.target?.closest('a')?.getAttribute('href');
    if (href === asPath) {
      if (showContact !== undefined) setShowContact(undefined);
      if (showMenu !== undefined) setShowMenu(undefined);
    }
  };

  const onContactLinkClick = () => {
    setShowContact(showContact === undefined ? window.scrollY : undefined);
  };

  const onMenuToggleClick = () => {
    setShowMenu(showMenu === undefined ? window.scrollY : undefined);
  };

  return (
    <>
      <header
        className={`${style.container} ${showMenu !== undefined ? style.showMenu : null}`}
        ref={mainHeaderRef}
      >
        <nav className={`${style.nav} ${isLoaded ? style.loaded : undefined}`}>
          {/* ${navScrollActivated ? style.navScrollActivated : null} */}
          <ul className={style.list}>
            {MENU_ITEMS.map((item) => {
              const itemState =
                showContact === undefined && item.href === asPath ? style.currentPage : null;
              return (
                <li className={`${style.listItem} `} key={item.label}>
                  <Link href={item.href}>
                    <a onClick={onLinkClick} className={`${itemState}`}>
                      {item.label}
                    </a>
                  </Link>
                </li>
              );
            })}
            {isDesktop ? (
              <li className={`${style.listItem} ${style.listItemContact}`}>
                <a
                  onClick={onContactLinkClick}
                  className={`${showContact !== undefined ? style.currentPage : null}`}
                >
                  Contact
                </a>
              </li>
            ) : null}
          </ul>
        </nav>

        <Contact />
      </header>

      {!isDesktop ? (
        <div className={`${style.menuToggle} ${showMenu !== undefined ? style.showMenu : null}`}>
          <span onClick={onMenuToggleClick}>{showMenu !== undefined ? 'Close' : 'Index'}</span>
        </div>
      ) : null}
    </>
  );
};

export const MainHeaderPlaceholder = (props: MainHeaderPlaceholderType): JSX.Element => {
  return (
    <header
      className={`${props.blank ? style.headerPlaceholderBlank : ''}  ${style.headerPlaceholder}`}
    >
    { !props.excludeGrid ?
      <Grid />
    : null }
    </header>
  );
};
