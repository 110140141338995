import React from 'react';
import Head from 'next/head';

// import Script from 'next/script';
import parse from 'html-react-parser';

export type AnalyticsType = {
  location: 'head' | 'top' | 'bottom';
  // purpose: string;
  // documentation: string;
  snippet: string;
};

export const Analytics = (props: AnalyticsType[]) => {
  let headSnippets: string[] = []
  let bodySnippets: string[] = []
  
  Object.values(props).map((item, index) => {
    if (item.location == 'head') {
      headSnippets.push(item.snippet)
    } else {
      bodySnippets.push(item.snippet)
    }
  })

  return (
    <>
      <Head>
        {parse(headSnippets.join(' '))}
      </Head>
      
      {parse(bodySnippets.join(' '))}
      {/* {Object.values(props)
          .filter((item) => {
            return item.location == 'top' || item.location == 'bottom';
          })
          .map((item, index) => {
            return parse(item.snippet);
            // return <Script
            //   strategy="afterInteractive"
            //   dangerouslySetInnerHTML={{
            //     __html: item.snippet,
            //   }}
            //   key={index}
            //   id={`top-${index}`}
            // />;
          })} */}
    </>
  );
};
