import React, { useEffect, useRef, useState } from 'react';
import { Router, useRouter } from 'next/router';
import { useWindowSize } from 'hooks/useWindowSize';

import { DefaultMediaBlockProps } from 'components/blocks/switchBlock/SwitchBlock';
import { MediaBlock } from 'components/blocks/mediaBlock/MediaBlock';
import { Logo } from 'components/logo/Logo';
import { MainHeaderPlaceholder } from 'components/mainHeader/MainHeader';

import Link from 'next/link';
import gsap from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { useGlobalContentContext } from 'context/GlobalContentContext';
import { useAppContext } from 'context/AppContext';

import { isDesktopWidth, setCSSVar } from 'utils/helpers';

import style from './Contact.module.scss';

if (typeof window !== 'undefined') {
  // Due to SSR we can only register when we are in the browser
  gsap.registerPlugin(ScrollTrigger);
  // gsap.config({});
}

export type ContactType = {
  contact_physical: string;
  contact_digital: string;
} & DefaultMediaBlockProps;

export type ContactProps = {
  reverse?: boolean;
};

export type RectType = {
  height: number;
  width: number;
};

export const Contact = (props: ContactProps) => {
  const router = useRouter();
  const { showContact, mainRef, mainScrollLock, setMainScrollLock } = useAppContext();
  const { data } = useGlobalContentContext();
  const contact = data.contact;
  const windowSize = useWindowSize();
  const [isDesktop, setIsDesktop] = useState<boolean>(false);

  const listenerRef = useRef<HTMLElement>(null);
  const rectRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const mediaRef = useRef<HTMLDivElement>(null);

  const mediaElement = mediaRef.current;

  const mediaYSetter = gsap.utils.pipe(
    // gsap.utils.clamp(-rect.height, 0),
    // (val: any) => ({ translateY: val }),
    gsap.quickSetter(mediaElement, 'y', 'px'),
  );

  const media: DefaultMediaBlockProps = {
    image: contact.image,
    image_height: contact.image_height,
    image_width: contact.image_width,
    phone_image: contact.phone_image,
    phone_image_height: contact.phone_image_height,
    phone_image_width: contact.phone_image_width,
    alt_text: contact.alt_text,
    aspect_ratio: contact.aspect_ratio,
  };

  useEffect(() => {
    // TODO potential fix for sticky items
    // https://github.com/sonsandco/refractory/blob/245f886d0c39fee3de5e04ae2f5aca894e5694bf/assets/j/main/lib/utilities/scroll_preventer.js#L243
    if (!mainRef.current || !containerRef.current || !mediaRef.current || !innerRef.current) return;
    const mainElement = mainRef.current;
    const containerElement = containerRef.current;
    const innerElement = innerRef.current;
    const mainRect = mainElement.getBoundingClientRect();
    // const containerRect = containerElement.getBoundingClientRect();
    if (showContact !== undefined) {
      // show
      // console.log(mainElement.offsetTop)
      gsap.set(mainElement, {
        position: 'fixed',
        left: mainRect.left,
        top: router.asPath === '/' || router.asPath === '/home' ? 0 : -window.scrollY,
        width: mainRect.width,
        pointerEvents: 'none',
        zIndex: -1,
      });
      window.scrollTo(0, 0);
      gsap.set(innerElement, {
        autoAlpha: 0,
      });
      gsap.to(innerElement, {
        autoAlpha: 1,
        duration: 0.6,
        delay: 0.5,
      });

      gsap.to(containerElement, {
        autoAlpha: 1,
        duration: 0.5,
        // ease: 'sine.inOut',
        onComplete: () => {
          gsap.set(containerElement, {
            position: 'relative',
            pointerEvents: 'all',
          });

          ScrollTrigger.create({
            start: 0,
            // end: updatedMediaData.height,
            scrub: true,
            useFrames: true,
            onUpdate(self: any) {
              mediaYSetter(-window.scrollY * 2.5);
            },
          });
        },
      });

      setMainScrollLock(showContact);
    } else {
      // hide
      if (!mainScrollLock) {
        gsap.set(mainElement, {
          top: 0
        })
      }
      gsap.to(innerElement, {
        autoAlpha: 0,
        duration: 0.6,
      });
      gsap.to(containerElement, {
        autoAlpha: 0,
        duration: 0.8,
        delay: 0.4,
        // ease: 'sine.inOut',
        onComplete: () => {
          gsap.set(mainElement, { clearProps: 'all' });
          gsap.set(containerElement, { clearProps: 'all' });
          gsap.set(innerElement, { clearProps: 'all' });
          if (mainScrollLock) {
            window.scrollTo(0, mainScrollLock || 0);
            setMainScrollLock(undefined);
          }

          ScrollTrigger.getAll().forEach((t: any) => {
            // TODO get ref.?
            t?.kill(false);
          });
          gsap.set(mediaElement, { clearProps: 'all' });
        },
      });
    }
    return () => {
      ScrollTrigger.getAll().forEach((t: any) => {
        // TODO get ref.?
        t?.kill(false);
      });
      gsap.set(mediaElement, { clearProps: 'all' });
    };
  }, [mainRef.current, containerRef.current, mediaRef.current, innerRef.current, showContact]);

  useEffect(() => {
    if (!media || !media.image) return;
    const desktop = isDesktopWidth(windowSize.width);
    setIsDesktop(desktop);

    // const contactImageKey = !desktop && media.phone_image ?'phone_image': 'image'
    // // @ts-ignore: Object is posibly undefined
    // const contactImageHeight = media[`${contactImageKey}_height`] / media[`${contactImageKey}_width`] * 100
    //   setCSSVar('--contact-image-height', `${contactImageHeight}vw`);
  }, [windowSize.width]);

  return (
    <div
      ref={containerRef}
      className={`
        ${style.container} 
        ${props.reverse ? style.reverse : null} 
        ${showContact !== undefined ? style.show : null}`}
    >
      <div className={style.inner} ref={innerRef}>
        {isDesktop ? <MainHeaderPlaceholder excludeGrid={true} /> : null}
        <div
          className={style.contactPhysical}
          dangerouslySetInnerHTML={{ __html: contact.contact_physical }}
        ></div>
        <div
          className={style.contactDigital}
          dangerouslySetInnerHTML={{ __html: contact.contact_digital }}
        ></div>
        <div className={style.socialMedia}>
          <a href={data.social_media.facebook} target="_blank" rel="noreferrer">
            Facebook
          </a>
          <a href={data.social_media.instagram} target="_blank" rel="noreferrer">
            Instagram
          </a>
          <a href={data.social_media.linkedin} target="_blank" rel="noreferrer">
            Linkedin
          </a>
        </div>
      </div>
      {isDesktop && media.image ? (
        <footer className={style.footer} ref={listenerRef}>
          <div className={`${style.clip} ${showContact !== undefined ? style.unclip : undefined}`}>
            <div className={`${style.rect}`} ref={rectRef}>
              <Logo listenerRef={listenerRef} rectRef={rectRef} location="contact" />
            </div>
            <div className={`${style.media}`} ref={mediaRef}>
              <MediaBlock
                media={media}
                layout="responsive"
                loading="eager"
                objectFit="cover"
                block_type="media"
                // priority={index < 3}
                sizes="100vw"
                phone_sizes="100vw"
              />
            </div>
          </div>
        </footer>
      ) : null}
    </div>
  );
};
