import React, { useEffect, useRef, useState } from 'react';

import gsap from 'gsap/dist/gsap';

import { useWindowSize } from 'hooks/useWindowSize';

import style from './Grid.module.scss';
import { isDesktopWidth } from 'utils/helpers';
import { GRID_COUNT } from 'siteConfig';

export type GridType = {
  reverse?: boolean;
  back?: boolean;
};

export const Grid = ({ reverse, back }: GridType): JSX.Element => {
  const gridRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [isDesktop, setIsDesktop] = useState<boolean>(false);

  useEffect(() => {
    const desktop = isDesktopWidth(windowSize.width);
    setIsDesktop(desktop);
  }, [windowSize.width]);

  useEffect(() => {
    if (!isDesktop || !gridRef.current) return;
    const gridElement = gridRef.current;
    // let hideRequested = false;

    const hideGrid = () => {
      gsap.to(gridElement, {
        opacity: 0,
        duration: 1,
        onComplete: () => {
          // hideRequested = false;
          gsap.to(gridElement, {
            opacity: 1,
            duration: 5,
            delay: 2,
            overwrite: true,
            // onComplete: () => {
            //   hideRequested = false;
            // }
          });
        },
      });
    };

    const onScroll = (e: Event) => {
      // if (!hideRequested) {
      //   hideRequested = true;
      hideGrid();
      // }
    };
    gsap.set(gridElement, { opacity: 0 });
    // if (!hideRequested) {
    //   hideRequested = true;
    hideGrid();
    // }

    window.addEventListener('scroll', onScroll);
    return () => {
      // hideRequested = false;
      gsap.set(gridElement, { clearProps: 'all' });
      window.removeEventListener('scroll', onScroll);
    };
  }, [isDesktop, gridRef.current]);
  return (
    <>
      {isDesktop ? (
        <div className={`${style.container} 
                         ${reverse ? style.reverse : undefined}
                         ${back ? style.back : undefined}`} 
             ref={gridRef}>
          <div className={style.verticalContainer}>
            {[...Array(GRID_COUNT)].map((_, index) => {
              return (
                <div className={style.vertical} key={index}>
                  {/* {index} */}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};
