import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { NEXT_PRIVATE_API, NEXT_PUBLIC_API, BREAKPOINT_MD  } from '../siteConfig';

// https://stackoverflow.com/questions/8188548/splitting-a-js-array-into-n-arrays
export const splitToChunks = (arrayRaw, parts) => {
  const result = [];
  const array = [...arrayRaw];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
};

export const formatDate = (d) => {
  return format(parseISO(d), 'MMM dd yyyy');
};

export const getOrientation = (w, h) => {
  if (w == h) return `square`;
  if (w > h) return `landscape`;
  if (w < h) return `portrait`;
};

export const getPublicAPI = () => {
  return NEXT_PUBLIC_API;
};

export const getPrivateAPI = () => {
  return NEXT_PRIVATE_API;
};

export const getDomPos = (selector) => {
  return document.querySelector(selector)?.getBoundingClientRect().x;
};
export const isBrowser = () => {
  return typeof window !== 'undefined';
};

// TODO change to sm, md, lg.
export const isMobileWidth = (w) => {
  if (!w && isBrowser()) {
    return window.innerWidth < BREAKPOINT_MD;
  }
  return w < BREAKPOINT_MD;
};
export const isDesktopWidth = (w) => {
  if (!isBrowser()) {
    return true;
  }
  return w >= BREAKPOINT_MD;
};

export const hasTouch = () => document.documentElement.classList.contains('touchevents');

export const isSafari = () => {
  return (
    navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') == -1
  );
};

export const setCSSVar = (varName, value) => {
  if (!value) debugger;
  document.documentElement.style.setProperty(varName, value);
};
export const getCSSVar = (varName) => {
  if (!isBrowser()) return 0;
  return parseFloat(getComputedStyle(document.documentElement).getPropertyValue(varName));
};

export const isIOS = () => {
  const ua = navigator.userAgent;
  const ios =
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  return ios;
};

export const getHTMLFontSize = () => {
  if (!isBrowser()) return 1;

  const style = window.getComputedStyle(document.documentElement).getPropertyValue('font-size');
  const fontSizeInPixels = parseFloat(style);
  return fontSizeInPixels;
};

export const getGridColumnWidth = (el) => {
  // if we don't pass an el, we treat it as full screenwidth
  if (el) {
    console.warn('needs implementation');
    return 0;
  }

  // get the amount of pixels a col should be
  const pixelPerColumn = window.innerWidth / getCSSVar('--standard-cols');

  return pixelPerColumn;
};

export const pixelToRem = (pixel) => {
  // const getHTMLFontSize
  return pixel / getHTMLFontSize();
};
export const remToPixel = (rem) => {
  // const getHTMLFontSize
  return rem * getHTMLFontSize();
};

export const hasScrolledToBottom = (offset) => {
  return window.innerHeight + Math.ceil(window.pageYOffset) >= document.body.offsetHeight;
};

export const getPathWithoutQuery = (path) => {
  return path.split('?')[0];
};

// export const stickyFix = () => {
//   this.stickyData = []
//   // const winScroll = windowScroll()
//   // read
//   forEach(document.querySelectorAll('.sticky-fix'), sticky => {
//     if (getComputedStyle(sticky).getPropertyValue('position') === 'sticky') {
//       this.stickyData.push({
//         'el': sticky,
//         'parentBoundingRect': sticky.parentElement.getBoundingClientRect(),
//         'boundingRect': sticky.getBoundingClientRect(),
//         'offsetHeight': sticky.offsetHeight,
//         'offsetWidth': sticky.offsetWidth,
//         'pseudoEl': document.createElement('div'),
//         'preservedTop': sticky.style.top
//       })
//     }
//   })
//   // write
//   forEach(this.stickyData, stickyData => {
//     stickyData.el.style.position = 'absolute'
//     stickyData.el.style.top = `${stickyData.boundingRect.top - stickyData.parentBoundingRect.top}px`
//     // stickyData.el.style.left = 0 //`${winScroll.left + stickyData.boundingRect.left}px`
//     stickyData.pseudoEl.classList.add('sticky-pseudo')
//     stickyData.pseudoEl.style.height = `${stickyData.offsetHeight}px`
//     // stickyData.pseudoEl.style.width = `${stickyData.offsetWidth}px`
//     stickyData.el.parentNode.insertBefore(stickyData.pseudoEl, stickyData.el)
//   })
// }

// export const stickyUnfix = () => {
//   forEach(this.stickyData, stickyData => {
//     stickyData.el.style.position = null
//     stickyData.el.style.top = stickyData.preservedTop
//     stickyData.el.style.left = null
//     stickyData.pseudoEl.parentNode.removeChild(stickyData.pseudoEl)
//   })
// }