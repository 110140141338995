import { ImageProps } from 'next/image';
import { BlockImageProps, ImageBlock, ImageBlockType } from 'components/blocks/imageBlock/ImageBlock';
import { DefaultBlockProps, DefaultMediaBlockProps } from 'components/blocks/switchBlock/SwitchBlock';
import { VideoBlock, VideoBlockType } from 'components/blocks/videoBlock/VideoBlock';
import style from './MediaBlock.module.scss';

export type BlocksMediaType = DefaultBlockProps &
  Pick<
    ImageProps,
    | 'layout'
    | 'objectFit'
    | 'objectPosition'
    | 'loading'
    | 'width'
    | 'height'
    | 'priority'
    | 'sizes'
    | 'blurDataURL'
  > & {
    phone_sizes?: string;
    loadAnimation?: ImageBlockType['loadAnimation'];
    desktopLoadedCallback?: () => void;
    mobileLoadedCallback?: () => void;
    block_type: 'media';
    media: DefaultMediaBlockProps;
  };

// there is ALWAYS an image
// if there is also a video, use the image as a "poster"
export const MediaBlock = (props: BlocksMediaType): JSX.Element | null => {
  if (!props.media) {
    return null;
  }

  const noDimensionsNeededFor: any[] = ['fill'];
  const skipDimensions = noDimensionsNeededFor.includes(props.layout);

  //  no video, display a single image

  if (!props.media.video) {
    const mobile: BlockImageProps | undefined = props.media.phone_image
      ? {
          layout: props.layout,
          objectFit: props.objectFit,
          objectPosition: props.objectPosition,
          src: props.media.phone_image,
          sizes: props.phone_sizes ? props.phone_sizes : props.sizes,
          width: skipDimensions ? undefined : props.media.phone_image_width,
          height: skipDimensions ? undefined : props.media.phone_image_height,
          priority: props.priority,
          blurDataURL: props.media.phone_blurDataURL ? props.media.phone_blurDataURL : undefined,
        }
      : undefined;
    return (
      <figure className={`${style.container} `}>
        <ImageBlock
          desktop={{
            layout: props.layout,
            objectFit: props.objectFit,
            objectPosition: props.objectPosition,
            sizes: props.sizes,
            src: props.media.image,
            width: skipDimensions ? undefined : props.media.image_width,
            height: skipDimensions ? undefined : props.media.image_height,
            priority: props.priority,
            blurDataURL: props.media.blurDataURL ? props.media.blurDataURL : undefined,
          }}
          mobile={mobile}
          desktopLoadedCallback={props.desktopLoadedCallback}
          mobileLoadedCallback={props.mobileLoadedCallback}
          alt={props.media.alt_text}
          loadAnimation={props.loadAnimation}
        />
        {props.media.caption && <figcaption className={style.caption}>{props.media.caption}</figcaption>}
      </figure>
    );
  }
  // there is a video (and an image), display a video
  if (props.media.video) {
    // TS being a pain..... can't spread here, it'll loose narrowing
    const videoProps = Object.assign(props.media);
    return <VideoBlock {...videoProps} />;
  }
  return null;
};
