import React from 'react';
import Head from 'next/head';

export type MetaDataType = {
  meta_title?: string;
  meta_description?: string;
  meta_image?: string;
};

export const MetaData = (props: MetaDataType) => {
  const { meta_title, meta_description, meta_image } = props;

  return (
    <Head>
      <title>{meta_title}</title>
      <meta property="og:title" content={meta_title} key="title" />
      {meta_description && <meta name="description" property="og:description" content={meta_description} key="description" />}
      {meta_image && <meta property="og:image" content={meta_image} key="image" />}
    </Head>
  );
};
