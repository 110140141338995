import { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import { useRouterScroll } from '@moxy/next-router-scroll';
import { isHistoryEntryFromPopState } from '@moxy/react-page-swapper';
import PageSwapper from '@moxy/react-page-swapper';

import { MetaData, MetaDataType } from 'components/metaData/MetaData';
import { Analytics } from 'components/analytics/Analytics';
import { GlobalContentContextType } from 'context/GlobalContentContext';

import { useAppContext } from 'context/AppContext';
import { PageTransition, PageTransitionProps } from './pageTransition/PageTransition';

import style from './AppInner.module.scss';

interface AdditionalPageProps {
  meta_data?: MetaDataType;
  globalContentContextData?: GlobalContentContextType
}

export interface AppInnerProps extends AppProps {
  pageProps: AdditionalPageProps;
}

class PatchedPageSwapper extends PageSwapper {
  beginSwap() {
    window.requestAnimationFrame(() => {
      super.beginSwap();
      // const element = this.ref.current;
      // I don't like this solution, but need to stop the
      // content scrolling until the old content is removed.
      // width / height are locked in super()
      document.body.classList.add(style.pageTransitioning);
    });
  }
}

PatchedPageSwapper.propTypes = PageSwapper.propTypes;

export const AppInner = ({ Component, pageProps }: AppInnerProps) => {
  const router = useRouter();
  const { updateScroll } = useRouterScroll();
  const {
    showMenu,
    setShowMenu,
    showContact,
    setShowContact,
    requestingNextProject,
    setRequestingNextProject,
    requestingFilteredCategory,
    setRequestingFilteredCategory,
    setShowCategoryFilters,
    mainScrollLock,
    setMainScrollLock,
  } = useAppContext();

  const getNodeKey = (path: string) => {
    return path.split('?')[0];
  };

  const getAnimation = (nodeKey: string, prevNodeKey: string) => {
    if (showMenu) {
      return 'menu';
    } else if (showContact) {
      return 'contact';
    } else if (requestingFilteredCategory) {
      return 'filtering';
    } else if (requestingNextProject) {
      return 'next';
    } else if (isHistoryEntryFromPopState()) {
      return 'pop';
    }
    return 'fade';
  };

  const getMode = (nodeKey: string, prevNodeKey: string) => {
    if (['fade', 'filtering'].includes(getAnimation(nodeKey, prevNodeKey))) {
      return 'out-in';
    }
    return 'simultaneous';
  };

  const getUpdateScroll = (prevContext: any, content: any) => {
    const { nodeKey } = prevContext;
    // if (isHistoryEntryFromPopState() && ['/projects'].includes(nodeKey)) {
    if (isHistoryEntryFromPopState()) {
      return updateScroll(prevContext, content);
    } else {
      return window.scrollTo(0, 0);
    }
  };

  const handleComplete = (nodeKey: string, nextNodeKey: string) => {
    if (showMenu || showContact) {
      setMainScrollLock(undefined);
      window.scrollTo(0, 0);
    }
    window.setTimeout(() => {
      setRequestingNextProject(false);
      if (requestingFilteredCategory) {
        setRequestingFilteredCategory(false);
      } else {
        setShowCategoryFilters(false);
      }
      window.requestAnimationFrame(() => {
        document.body.classList.remove(style.pageTransitioning);
        setShowMenu(undefined);
        setShowContact(undefined);
      });
    }, 300);
  };

  if (typeof history !== 'undefined') {
    history.scrollRestoration = 'manual';
  }

  return (
    <>
      {pageProps.meta_data && <MetaData {...pageProps.meta_data} />}
      {pageProps.globalContentContextData && pageProps.globalContentContextData.analytics && (
        <Analytics {...pageProps.globalContentContextData.analytics} />
      )}
      {/* @ts-ignore */}
      <PatchedPageSwapper
        className={`${style.pageSwapper} js-page-swapper`}
        updateScroll={getUpdateScroll}
        onSwapEnd={handleComplete}
        // @ts-ignore
        node={<Component {...pageProps} />}
        nodeKey={getNodeKey(router.asPath)}
        animation={getAnimation}
        mode={getMode}
      >
        {(props: PageTransitionProps) => <PageTransition {...props} />}
      </PatchedPageSwapper>
    </>
  );
};
