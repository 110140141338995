import { createContext, useContext } from 'react';
import { getPrivateAPI, getPublicAPI } from 'utils/helpers';

import { MetaDataType } from 'components/metaData/MetaData';
import { AnalyticsType } from 'components/analytics/Analytics';
import { ContactType } from 'components/contact/Contact'
import { ProjectListingType, ProjectCategoryType } from 'pages/projects/[slug]';


export type GlobalContentContextType = {
  contact: ContactType;
  social_media: {[key: string]: string;};
  analytics: AnalyticsType[];
  site_meta_data: MetaDataType;
  projects: ProjectListingType[];
  categories: ProjectCategoryType[];
};

export type GlobalContentContextStateType = {
  data: GlobalContentContextType;
  setData: (data: GlobalContentContextType) => void;
};

// TODO get defaults from _app
export const GlobalContentContext = createContext<GlobalContentContextStateType>({
  data: {
    contact: {
      image_height: 0,
      image_width: 0,
      image: "",
      contact_physical: "",
      contact_digital: "",
      aspect_ratio: {
          image: "landscape"
      },
      alt_text: ""
    },
    social_media: {},
    analytics: [],
    site_meta_data: {},
    projects: [],
    categories: []
  },
  setData: (val) => {},
});

export const useGlobalContentContext = (): GlobalContentContextStateType =>
  useContext(GlobalContentContext);
