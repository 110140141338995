import { createContext, useContext, RefObject, useRef } from 'react';

export type AppContextType = {
  mainScrollLock: number | undefined;
  setMainScrollLock: (val: number | undefined) => void;

  showMenu: number | undefined;
  setShowMenu: (val: number | undefined) => void;

  showContact: number | undefined;
  setShowContact: (val: number | undefined) => void;

  requestingNextProject: boolean | string;
  setRequestingNextProject: (val: boolean | string) => void;

  requestingFilteredCategory: boolean | string;
  setRequestingFilteredCategory: (val: boolean | string) => void;

  showCategoryFilters: boolean;
  setShowCategoryFilters: (val: boolean) => void;

  mainHeaderRef: RefObject<HTMLElement>;
  mainRef: RefObject<HTMLElement>;
};

export const AppContext = createContext<AppContextType>({
  mainScrollLock: undefined,
  setMainScrollLock: (val) => {},

  showMenu: undefined,
  setShowMenu: (val) => {},

  showContact: undefined,
  setShowContact: (val) => {},

  requestingNextProject: false,
  setRequestingNextProject: (val) => {},

  requestingFilteredCategory: false,
  setRequestingFilteredCategory: (val) => {},

  showCategoryFilters: false,
  setShowCategoryFilters: (val) => {},

  mainHeaderRef: { current: null },
  mainRef: { current: null },
});

export const useAppContext = (): AppContextType => useContext(AppContext);
