import { useState, useEffect, useRef } from 'react';
import Image, { ImageProps } from 'next/image';
import gsap from 'gsap/dist/gsap';

import { isMobileWidth } from 'utils/helpers';
import { useWindowSize } from 'hooks/useWindowSize';

import style from './ImageBlock.module.scss';

//  docs: https://nextjs.org/docs/basic-features/image-optimization
//        https://nextjs.org/docs/api-reference/next/image

export type BlockImageProps = ImageProps & {};

export type ImageBlockType = {
  desktop: BlockImageProps;
  mobile?: BlockImageProps;
  desktopLoadedCallback?: () => void;
  mobileLoadedCallback?: () => void;
  alt: string;
  loadAnimation?: 'fade' | 'transform';
};

export const ImageBlock = ({
  desktop,
  mobile,
  desktopLoadedCallback,
  mobileLoadedCallback,
  alt = '',
  loadAnimation,
}: ImageBlockType): JSX.Element => {
  const placeholderSVG = useRef<any>(null); // no such type as HTMLSVGElement
  const placeholderRectangle = useRef<any>(null); // no such type as HTMLSVGElement
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isDesktopLoaded, setIsDesktopLoaded] = useState<boolean>(false);
  const [isMobileLoaded, setIsMobileLoaded] = useState<boolean>(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    // window size
    setIsMobile(isMobileWidth(windowSize.width));
  }, [windowSize]);

  useEffect(() => {
    if (!isMobileLoaded && !isDesktopLoaded) return;

    let animationOptions;
    const rootAnimationOptions = {
      onComplete() {
        requestAnimationFrame(() => {
          placeholderSVG.current && placeholderSVG.current.remove();
        })
      },
    };

    switch (loadAnimation) {
      case 'transform':
        animationOptions = Object.assign({}, rootAnimationOptions, {
          // yPercent: placeholderSVG.current.getBoundingClientRect().height,
          yPercent: 100,
          duration: 0.55,
          delay: 2,
          ease: 'power1.in',
        });
        break;
      default:
        animationOptions = Object.assign({}, rootAnimationOptions, {
          opacity: 0,
          duration: 0.3,
        });
        break;
    }
    animationOptions && gsap.to(placeholderRectangle.current, animationOptions);
  }, [isMobileLoaded, isDesktopLoaded]);

  const onDesktopLoadingComplete = () => {
    setIsDesktopLoaded(true);
    desktopLoadedCallback && desktopLoadedCallback();
  };
  const onMobileLoadingComplete = () => {
    setIsMobileLoaded(true);
    mobileLoadedCallback && mobileLoadedCallback();
  };

  const mobileLoadState = isMobileLoaded ? style.imageLoaded : null;
  const desktopLoadState = isDesktopLoaded ? style.imageLoaded : null;

  return (
    <>
      {mobile && isMobile ? (
        <>
          <svg
            width={mobile.width ? mobile.width : 1}
            height={mobile.height ? mobile.height : 1}
            viewBox={`0 0 ${mobile.width ? mobile.width : 1} ${mobile.height ? mobile.height : 1}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // TODO if layout == fill.?
            preserveAspectRatio="none"
            className={`${style.placeholder} ${mobile.layout}`}
            ref={placeholderSVG}
          >
            <rect
              ref={placeholderRectangle}
              className={loadAnimation ? style[loadAnimation] : undefined}
            />
          </svg>

          <Image
            className={`${style.mobileImage} ${mobileLoadState} block-image-mobile`}
            {...mobile}
            alt={alt}
            onLoadingComplete={onMobileLoadingComplete}
            // draggable={false}
            // placeholder="blur"
          />
        </>
      ) : (
        <>
          <svg
            width={desktop.width ? desktop.width : 1}
            height={desktop.height ? desktop.height : 1}
            viewBox={`0 0 ${desktop.width ? desktop.width : 1} ${
              desktop.height ? desktop.height : 1
            }`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // TODO if layout == fill.?
            preserveAspectRatio="none"
            className={`${style.placeholder} ${desktop.layout}`}
            ref={placeholderSVG}
          >
            <rect
              ref={placeholderRectangle}
              className={loadAnimation ? style[loadAnimation] : undefined}
            />
          </svg>

          <Image
            className={`${style.desktopImage} ${desktopLoadState} block-image-desktop`}
            {...desktop}
            // draggable={false}
            // placeholder="blur"
            alt={alt}
            onLoadingComplete={onDesktopLoadingComplete}
          />
        </>
      )}
    </>
  );
};
