/*  site config can be imported from process.env.*,
    to customise to local / production settings

    these files are located at -
    ./.env.development.local or ./env.production.local */



export const NEXT_HOST = process.env.NEXT_HOST;
export const NEXT_PRIVATE_API = process.env.NEXT_PRIVATE_API;
export const NEXT_PUBLIC_API = process.env.NEXT_PUBLIC_API;
export const PREVIEW_KEY = process.env.PREVIEW_KEY;


export const DEBUG = process.env.NODE_ENV !== 'production';

/*   */
export const GA_TRACKING_ID = '';


/*  Next.js will attempt to re-generate the page:
    - When a request comes in
    - At most once every 10 */
export const NEXTJS_REVALIDATE_PAGE_IN_SECONDS = 10;
export const NEXTJS_REVALIDATE_GLOBAL_IN_SECONDS = 60 * 10;


/* TODO reference to next.js router paths.
*/
export const MENU_ITEMS = [
    { href: '/projects/all', label: 'Projects' },
    { href: '/studio', label: 'Studio' },
    { href: '/process', label: 'Process' },
  ];



/*  TODO consider making this a shared variable.? */
export const GRID_COUNT = 11;
export const GRID_GAP = '2%';


export const BREAKPOINT_SM = 500;
export const BREAKPOINT_MD = 800;
export const BREAKPOINT_LG = 1220;
export const BREAKPOINT_XL = 1440;
export const BREAKPOINT_XXL = 1600;
