import React, { cloneElement, ReactElement, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
// import { getCSSVar, setCSSVar } from 'utils/helpers';
import styles from './PageTransition.module.scss';
import { DEBUG } from 'siteConfig';

/* try not to add any context here : add it in AppInner and pass it through 
   in either *mode* *animation* *inProp*, then define which transitions to use
    based on those variables */

export type PageTransitionProps = {
  node: ReactElement;
  animation: string;
  mode: string;
  transitioning: boolean;
  style: {};
  in: boolean;
  onEntered: () => void;
  onExited: () => void;
};

export type CloneProps = {
  type: string;
  transitioning: boolean;
};

export const PageTransition = ({
  node,
  animation,
  mode,
  transitioning = false,
  style,
  in: inProp = false,
  onEntered,
  onExited,
}: PageTransitionProps) => {
  const PAGE_TRANSITION_DEBUG = DEBUG && false;

  PAGE_TRANSITION_DEBUG && console.log(`----------------------`);
  PAGE_TRANSITION_DEBUG && console.log(node.props.meta_data.meta_title);
  PAGE_TRANSITION_DEBUG &&
    console.log(
      `${mode} ${inProp ? 'new' : 'old'} ${animation} ${transitioning ? 'transitioning' : ''}`,
    );
  PAGE_TRANSITION_DEBUG && console.log(style);

  const getTimeout = (animation: string) => {
    /* IMPORTANT! this must match css transitions */
    switch (animation) {
      case 'next':
        return 750;
      case 'menu':
        return 200;
      case 'filtering':
        return 650;
      case 'fade':
        return 650;
      default:
        return 0;
    }
  };

  const handleEnter = (e: any) => {
    PAGE_TRANSITION_DEBUG && console.log(`enter ${animation}`);
  };
  const handleEntered = (e: any) => {
    PAGE_TRANSITION_DEBUG && console.log(`entered ${animation}`);
    onEntered();
  };
  const handleExited = (e: any) => {
    PAGE_TRANSITION_DEBUG && console.log(`exited ${animation}`);
    onExited();
  };

  const bindEndListener = (node: any, done: any) => {
    PAGE_TRANSITION_DEBUG && console.log('end');
    node.addEventListener('transitionend', done, false);
  };

  return (
    <CSSTransition
      in={inProp}
      onEntered={handleEntered}
      onExited={handleExited}
      onEnter={handleEnter}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      timeout={getTimeout(animation)}
      addEndListener={bindEndListener}
    >
      <div className={styles[animation]} style={{ ...style }}>
        {cloneElement(node, {
          pageTransition: {
            type: inProp ? 'enter' : 'exit',
            transitioning: transitioning,
          },
        })}
      </div>
    </CSSTransition>
  );
};
