import gsap from 'gsap/dist/gsap';
import { useEffect, useRef, useState } from 'react';
import { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { Size, useWindowSize } from 'hooks/useWindowSize';
import { isMobileWidth } from 'utils/helpers';
import { DefaultMediaBlockProps } from 'components/blocks/switchBlock/SwitchBlock';
import style from './VideoBlock.module.scss';
import { VideoJSPlayer } from './VideoJSPlayer';

export type VideoBlockType = DefaultMediaBlockProps &
  Pick<VideoJsPlayerOptions, 'controls' | 'autoplay' | 'loop'>;

export const VideoBlock = (props: VideoBlockType): JSX.Element => {
  const size: Size = useWindowSize();
  // const { resetScreenSaverTimer } = useAppContext();

  const isMobileDevice = isMobileWidth(size.width);
  const containerRef = useRef<HTMLDivElement>(null);
  const darkenerRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const [disableMouseMovement, setDisableMouseMovement] = useState<boolean>(false);
  const [mouseIsOver, setMouseIsOver] = useState<boolean>(false);
  const mousePosRef = useRef<{ x: number; y: number } | null>();
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false);

  const poster = isMobileDevice && props.phone_image ? props.phone_image : props.image;
  const src = isMobileDevice && props.phone_video ? props.phone_video : props.video;
  const TIMEOUT = 0.5;
  const videoJsOptions: VideoJsPlayerOptions = {
    // lookup the options in the docs for more options
    responsive: true,
    inactivityTimeout: TIMEOUT * 1000,
    controlBar: props.controls
      ? {
          fullscreenToggle: false,
          volumePanel: false,
          playToggle: false,
          pictureInPictureToggle: false,
          remainingTimeDisplay: false,
          durationDisplay: false,
          currentTimeDisplay: false,
          timeDivider: false,
        }
      : false,

    controls: true,
    // we are using custom Play/Pause components, so no need to show the button
    bigPlayButton: false,
    loop: props.loop,
    autoplay: props.autoplay,
    muted: props.autoplay,

    fill: true,
    preload: 'auto',
    userActions: {
      doubleClick: false,
    },
    poster: poster,
    sources: [
      {
        src: src || '',
      },
    ],
  };

  const getCenterPos = () => {
    const rect = containerRef.current?.getBoundingClientRect() || { width: 0, height: 0 };
    const iconRect = iconRef.current?.getBoundingClientRect() || { width: 0, height: 0 };
    const x = rect.width / 2 - iconRect.width / 2;
    const y = rect.height / 2 - iconRect.height / 2;
    return { x, y };
  };
  const onVideoEnd = () => {
    setVideoIsPlaying(false);
  };
  const onReady = (player: VideoJsPlayer) => {
    const el = player.el();
    const progressbar = el.querySelector('.vjs-control-bar');
    progressbar?.addEventListener('mouseover', () => {
      setDisableMouseMovement(true);
      mousePosRef.current = null;
      gsap.to(iconRef.current, { autoAlpha: 0 });
    });
    progressbar?.addEventListener('mouseleave', () => {
      mousePosRef.current = null;
      setDisableMouseMovement(false);
      gsap.to(iconRef.current, { autoAlpha: 1 });
    });
  };
  const onVideoProgress = () => {
    // resetScreenSaverTimer();
  };
  const onVideoPlay = () => {
    // resetScreenSaverTimer();
    setVideoIsPlaying(true);
    mousePosRef.current = null;
    gsap.to(darkenerRef.current, { autoAlpha: 0, duration: 0.1 });
    gsap.to(iconRef.current, { autoAlpha: 0, delay: TIMEOUT });
    if (!props.controls) return;
  };
  const onVideoPause = () => {
    mousePosRef.current = null;
    setVideoIsPlaying(false);
    gsap.to(darkenerRef.current, { autoAlpha: 1, duration: 0.1 });
    gsap.killTweensOf(hideControls);
    gsap.killTweensOf(iconRef.current);
    if (mouseIsOver) {
      gsap.to(iconRef.current, { autoAlpha: 1 });
    } else {
      const { x, y } = getCenterPos();
      gsap.set(iconRef.current, { x, y });
      gsap.to(iconRef.current, { autoAlpha: 1 });
    }
  };

  const onMouseLeave = () => {
    gsap.killTweensOf(hideControls);
    if (mouseIsOver) setMouseIsOver(false);
    if (containerRef.current) {
      if (videoIsPlaying) {
        gsap.to(iconRef.current, { autoAlpha: 0 });
      } else {
        const { x, y } = getCenterPos();

        gsap.to(iconRef.current, { autoAlpha: 1 });
      }
    }
  };

  const hideControls = () => {
    if (videoIsPlaying) mousePosRef.current = null;
    gsap.to(iconRef.current, { autoAlpha: videoIsPlaying ? 0 : 1 });
  };

  const onMouseMove = (e: any) => {
    if (disableMouseMovement) {
      return;
    }
    if (!mousePosRef.current) {
      mousePosRef.current = { x: e.clientX, y: e.clientY };
    }
    const dx = e.clientX - mousePosRef.current.x;
    const dy = e.clientY - mousePosRef.current.y;
    const distTravelled = Math.sqrt(dx * dx + dy * dy);

    if (!containerRef.current) throw Error('container should be there but isnt');
    const rect = containerRef.current.getBoundingClientRect();

    const iconRect = iconRef.current?.getBoundingClientRect() || { width: 0, height: 0 };
    const padding = 25;
    const x = Math.max(
      padding,
      Math.min(e.clientX - rect.x - iconRect.width / 2, rect.width - iconRect.width - padding),
    );
    const y = Math.max(
      padding,
      Math.min(e.clientY - rect.top - iconRect.height / 2, rect.height - iconRect.height),
    );

    rect.height - iconRect.height; //e.clientY - iconRect.height / 2 - rect.top;
    gsap.killTweensOf(hideControls);
    gsap.to(iconRef.current, { x, y, transformOrigin: 'center center' });

    if (distTravelled > 120) {
      gsap.to(iconRef.current, { autoAlpha: 1 });
    }
    gsap.delayedCall(0.5, hideControls);
    if (!mouseIsOver) setMouseIsOver(true);
    //save the pos
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('mousemove', onMouseMove);
      containerRef.current.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      containerRef.current?.removeEventListener('mousemove', onMouseMove);
      containerRef.current?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [containerRef.current, videoIsPlaying, mouseIsOver, disableMouseMovement]);

  const dimensionsOfPoster = {
    w: isMobileDevice && props.phone_image ? props.phone_image_width : props.image_width,
    h: isMobileDevice && props.phone_image ? props.phone_image_height : props.image_height,
  };
  const apectRatioOfVideo = `${dimensionsOfPoster.w} / ${dimensionsOfPoster.h}`;
  const containerStyle = { aspectRatio: apectRatioOfVideo };

  const videoControlsState = props.controls ? '' : style.videoStateIconHidden;

  return (
    <div ref={containerRef} className={`${style.container} block-video`}>
      <div className={`${style.videoStateIcon} `} ref={iconRef}>
        <span className={`${videoControlsState}`}>{videoIsPlaying ? 'pause' : 'play'}</span>
      </div>
      <div className={style.aspectContainer} style={containerStyle}>
        <div ref={darkenerRef} className={style.darkener} />
        <VideoJSPlayer
          playsInline={true}
          onVideoEnd={onVideoEnd}
          onVideoPlay={onVideoPlay}
          onVideoPause={onVideoPause}
          onVideoProgress={onVideoProgress}
          options={videoJsOptions}
          onReady={onReady}
        />
      </div>
    </div>
  );
};
