import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import gsap from 'gsap/dist/gsap';

import { useWindowSize } from 'hooks/useWindowSize';
import { isDesktopWidth } from 'utils/helpers';

import { useAppContext } from 'context/AppContext';

import style from './Logo.module.scss';

import Mo__erWhite from './mo__er-white.svg';
import MollerWhiteRotated from './moller-white-rotated.svg';
// import __ll__White from './__ll__-white.svg';

export type LogoType = {
  location: 'home' | 'contact' | 'footer';
  listenerRef: React.RefObject<HTMLElement>;
  rectRef: React.RefObject<HTMLElement>;
};

export const Logo = (props: LogoType) => {
  const { location, listenerRef, rectRef } = props;
  const { showContact, mainHeaderRef } = useAppContext();
  const windowSize = useWindowSize();
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [hoverAvailable, setHoverAvailable] = useState<boolean>(false);
  const animated = ['home'].includes(location);
  // const listenToMouse = ['holding'].includes(location);
  const listenToScroll = ['footer'].includes(location);
  const router = useRouter();

  const svgHeight = 495;
  const svgWidth = 1404;

  const __ll__WhiteRef = useRef<SVGSVGElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHoverAvailable(window.matchMedia('(hover: hover)').matches);
  }, []);

  useEffect(() => {
    const desktop = isDesktopWidth(windowSize.width);
    setIsDesktop(desktop);
  }, [windowSize.width]);

  useEffect(() => {
    // handle animations with scrollPosition
    if (
      !__ll__WhiteRef.current ||
      !listenerRef.current ||
      !rectRef.current ||
      !mainHeaderRef.current ||
      !listenToScroll ||
      showContact
    )
      return;
    const listenerElement = listenerRef.current;
    const rectElement = rectRef.current;
    const mainHeaderNavElement = mainHeaderRef.current.querySelector('nav');
    const __ll__WhiteElement = __ll__WhiteRef.current;
    const rect = rectElement.getBoundingClientRect();
    const minHeight = rect.width * (svgHeight / svgWidth);
    const maxHeight = rect.height;
    const offsetTop = listenerElement.getBoundingClientRect().top + window.scrollY;
    const buffer = mainHeaderNavElement ? mainHeaderNavElement.offsetHeight : 0;
    let currentState = maxHeight;

    gsap.set(__ll__WhiteElement, { height: currentState });

    const onScroll = (e: Event) => {
      const requestedState = window.scrollY >= offsetTop - buffer ? minHeight : maxHeight;
      // console.log(`${requestedState} ${offsetTop} ${buffer} ${window.scrollY}`);
      if (currentState !== requestedState) {
        gsap.to(__ll__WhiteElement, {
          height: requestedState,
          duration: 0.3,
          ease: 'sine.inOut',
        });
        currentState = requestedState;
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [
    windowSize,
    __ll__WhiteRef.current,
    listenerRef.current,
    rectRef.current,
    mainHeaderRef.current,
    showContact,
  ]);

  useEffect(() => {
    // handle animations with scrollPosition
    if (
      !__ll__WhiteRef.current ||
      !listenerRef.current ||
      !rectRef.current ||
      !mainHeaderRef.current ||
      !listenToScroll ||
      showContact
    )
      return;
    const listenerElement = listenerRef.current;
    const rectElement = rectRef.current;
    const mainHeaderNavElement = mainHeaderRef.current.querySelector('nav');
    const __ll__WhiteElement = __ll__WhiteRef.current;
    const rect = rectElement.getBoundingClientRect();
    const minHeight = rect.width * (svgHeight / svgWidth);
    const maxHeight = rect.height;
    const offsetTop = listenerElement.getBoundingClientRect().top + window.scrollY;
    const buffer = mainHeaderNavElement ? mainHeaderNavElement.offsetHeight : 0;
    let currentState = maxHeight;

    gsap.set(__ll__WhiteElement, { height: currentState });

    const onScroll = (e: Event) => {
      const requestedState = window.scrollY >= offsetTop - buffer ? minHeight : maxHeight;
      // console.log(`${requestedState} ${offsetTop} ${buffer} ${window.scrollY}`);
      if (currentState !== requestedState) {
        gsap.to(__ll__WhiteElement, {
          height: requestedState,
          duration: 0.3,
          ease: 'sine.inOut',
        });
        currentState = requestedState;
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [
    windowSize,
    __ll__WhiteRef.current,
    listenerRef.current,
    rectRef.current,
    mainHeaderRef.current,
    showContact,
  ]);

  // useEffect(() => {
  //   // handle animations with animation (only on home)
  //   if (!__ll__WhiteRef.current || !rectRef.current || !animated) return;
  //   // const listenerElement = listenerRef.current;
  //   const rectElement = rectRef.current;
  //   const __ll__WhiteElement = __ll__WhiteRef.current;
  //   // const mainHeaderNavElement = mainHeaderRef.current.querySelector('nav');
  //   const rect = rectElement.getBoundingClientRect();
  //   const minHeight = rect.width * (svgHeight / svgWidth);
  //   const maxHeight = rect.height;
  //   // const offsetTop = rect.top + window.scrollY;
  //   // const buffer = mainHeaderNavElement ? mainHeaderNavElement.offsetHeight : 0;

  //   // gsap.set(__ll__WhiteElement, {
  //   //   height: maxHeight,
  //   // });

  //   gsap.to(__ll__WhiteElement, {
  //     height: minHeight,
  //     duration: 0.5,
  //     // delay: 0.4,
  //     ease: 'power1.inOut',
  //     overwrite: true,
  //   });
  // }, [animated, windowSize, __ll__WhiteRef.current, rectRef.current]);

  // useEffect(() => {
  //   // handle animations with mousePosition (only on holding page)
  //   if (
  //     !__ll__WhiteRef.current ||
  //     !listenerRef.current ||
  //     !mainHeaderRef.current ||
  //     !rectRef.current ||
  //     !listenToMouse
  //   )
  //     return;
  //   const listenerElement = listenerRef.current;
  //   const rectElement = rectRef.current;
  //   const __ll__WhiteElement = __ll__WhiteRef.current;
  //   const mainHeaderNavElement = mainHeaderRef.current.querySelector('nav');
  //   const rect = rectElement.getBoundingClientRect();
  //   const minHeight = rect.width * (svgHeight / svgWidth);
  //   const maxHeight = rect.height;
  //   const offsetTop = rect.top + window.scrollY;
  //   const buffer = mainHeaderNavElement ? mainHeaderNavElement.offsetHeight : 0;

  //   const onMouseMove = (e: MouseEvent) => {
  //     const shift = maxHeight - e.y + buffer;
  //     const to = gsap.utils.clamp(minHeight, maxHeight, shift);
  //     gsap.to(__ll__WhiteElement, { height: to, duration: 0.3 });
  //   };

  //   gsap.to(__ll__WhiteElement, {
  //     height: maxHeight,
  //     duration: 0.2,
  //     ease: 'power1.inOut',
  //     overwrite: true,
  //   });

  //   if (mouseDisabled) {
  //     gsap.to(__ll__WhiteElement, {
  //       height: minHeight,
  //       duration: 0.5,
  //       delay: 0.4,
  //       ease: 'power1.inOut',
  //       overwrite: true,
  //     });
  //   }

  //   listenerElement?.addEventListener('mousemove', onMouseMove);
  //   return () => {
  //     listenerElement?.removeEventListener('mousemove', onMouseMove);
  //   };
  // }, [
  //   listenToMouse,
  //   windowSize,
  //   hoverAvailable,
  //   __ll__WhiteRef.current,
  //   listenerRef.current,
  //   rectRef.current,
  //   mainHeaderRef.current,
  // ]);

  const onSVGClick = (e: any) => {
    e.preventDefault();
    router.push('/');
  };

  return (
    <div className={`${style.container} ${style[props.location]}`} ref={containerRef}>
      {isDesktop ? (
        <>
          <Mo__erWhite className={`${style.mo__er}`} onClick={onSVGClick} />
          {/* __ll__White */}
          <svg
            viewBox="0 0 1404 492"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className={`${style.__ll__}`}
            ref={__ll__WhiteRef}
            onClick={onSVGClick}
          >
            <rect x="776" width="110" height="492" fill="white" />
            <rect x="647" width="110" height="492" fill="white" />
          </svg>
        </>
      ) : (
        <MollerWhiteRotated className={`${style.mollerRotated}`} onClick={onSVGClick} />
      )}
    </div>
  );
};
